.search {
  opacity: 0;
  height: 0;
  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 119px;
  transition: top .4s, opacity .4s;
  background: #121b22;
  color: $aui-color-white;
  &.open {
    opacity: 1;
    height: auto;
    z-index: 100;
    top: 0;
    @include media-breakpoint-up(small) {
      top: 55px;
    }
  }
  &.closing {
    top: 119px;
    opacity: 0;
  }
  
  >.content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .search__top {
    
  }
  
  .search__results {
    overflow: hidden;
    .inner {
      height: 100%;
      overflow-y: auto;
    }
  }
  
  .result {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid $aui-color-gray40;
    width: 100%;
    .result__text {
      flex: 1 1 auto;
    }
    .result__image-wrapper {
      flex: 0 1 100%;
      @include media-breakpoint-up(small) {
        flex: 0 1 25%;
      }
    }
    .result__image {
      height: 200px;
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    .tags {
      a, span {
        margin-right: 10px;
      }
    }
  }
}

body.search-open {
  overflow: hidden;
}
