.cms-section-list {
  h1 {
    // .aui-headline-1 is too big
    @extend .aui-headline-2;
    font-weight: $aui-font-weight-bold;
    margin: 0 0 15px;
  }
  h2 {
    font-family: $aui-font-extended;
    @include aui-responsive-type(4, 1.2);
    font-weight: $aui-font-weight-bold;
    letter-spacing: -0.015em;
    margin: 0 0 15px;
  }
  h3 {
    @extend .aui-headline-3;
    margin: 0 0 15px;
  }
  h4 {
    @extend .aui-headline-4;
    margin: 0 0 15px;
  }
  h5 {
    @extend .aui-headline-5;
    margin: 0 0 15px;
  }
  h6 {
    @extend .aui-headline-6;
    margin: 0 0 15px;
  }
  a:not(.aui-button):not(.btn) {
    @extend .aui-textlink;
  }
  a.btn {
    @extend .aui-button;
    @extend .aui-button--primary;
    &:visited, &:hover {
      color: $aui-color-white;
    }
  }
  p {
    margin: 0 0 15px;
  }
  small {
    @extend .aui-small;
  }
  
  .content-image {
    img {
      display: block;
      width: 100%;
    }
  }
  
  .overview__content {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 660px;
  }
  
  .type-column-2,
  .type-column-3,
  .type-column-4 {
    column-fill: balance;
    column-gap: $grid-gutter-width;
    h1, h2, h3, {
      column-span: all;
    }
  }
  .type-column-2 {
    @include media-breakpoint-up(small) {
      column-count: 2;
    }
  }
  .type-column-3 {
    @include media-breakpoint-up(small) {
      column-count: 3;
    }
  }
  .type-column-4 {
    @include media-breakpoint-up(small) {
      column-count: 4;
    }
  }
  
  .section--quotes {
    padding: 0 40px;
    margin: 40px auto;
    border-top: 1px solid $aui-color-gray80;
    border-bottom: 1px solid $aui-color-gray80;
    @include media-breakpoint-up(small) {
      padding: 0 120px;
    }
    a {
      border: none;
    }
  }
  .content-quote {
    background: $aui-color-white;
    text-align: center;
    blockquote {
      max-width: 600px;
      padding: 20px 25px;
      margin: 0 auto;
      @include media-breakpoint-up(small) {
        padding: 20px 40px;
      }
    }
    p {
      font-size: 1.1em;
      line-height: 1.5;
      margin-bottom: 1.2em;
      @include media-breakpoint-up(small) {
        font-size: 1.3em;
      }
    }
  }
  
  > *:last-child {
    margin-bottom: 0 !important;
  }
}

.media-embed__background {
  video { width: 100%; }
}
.media-embed__width {
  max-width: 125vh;
  margin: 0 auto;
  .media-embed__height {
    width: 100%;
    height: 0;
    position: relative;
    padding: 56.25% 0 0 !important;
    iframe {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
