
.prizes-secondary {
  .thumbnail {
    max-height: 0;
    overflow: hidden;
    padding-bottom: 0;
    transition: max-height 1s, padding 1s;
  }
  &.expanded {
    .thumbnail {
      max-height: none;
      padding-bottom: 20px;
    }
  }
  &.expanding {
    .thumbnail {
      max-height: 1000px;
    }
  }
}
