.donation-hero {
  min-height: calc(100vh - 45px);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $aui-color-gray50;
  @include media-breakpoint-up(small) {
    min-height: calc(100vh - 119px);
  }
  
  &__inner {
    min-height: calc(100vh - 45px);
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    @include media-breakpoint-up(small) {
      background: none !important;
      min-height: calc(100vh - 119px);
    }
  }
  
  .cms-section-list {
    color: $aui-color-light;
    font-weight: bold;
  }
}

.donation-card {
  background: $aui-color-gray95;
  width: 100%;
  max-width: 750px;
  padding: 2.5rem .75rem;
  @include media-breakpoint-up(small) {
    padding: 2.5rem 5.25rem;
  }
  .aui-textfield {
    margin: 0 auto 2rem;
    max-width: 350px;
  }
}

.donation-options {
  background: $aui-color-white;
  border: 1px solid;
  color: $aui-color-gray50;
  font-family: $aui-font-extended;
  display: flex;
  width: 100%;
  margin: 0 0 1rem;
  label {
    border-left: 1px solid $aui-color-gray50;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 2px;
    cursor: pointer;
    &:first-child {
      border-left: none;
    }
    input {
      appearance: none;
      height: 0;
      opacity: 0;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 0;
      z-index: -1;
    }
    &:hover {
      background: $aui-color-gray50;
      color: $aui-color-white;
    }
    &.active {
      background: $aui-color-red;
      color: $aui-color-white;
    }
  }
}
