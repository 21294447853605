/**
 * NOTE
 * All animations happen in JS.
 */

$aui-spinner-color: rgba(#000, .6) !default;
$aui-spinner-color-progress: $aui-color-red !default;
$aui-spinner-size: rem(96) !default;
$aui-spinner-size-continuous: rem(48) !default;

.aui-spinner {
  display: inline-block;
  position: relative;
  width: $aui-spinner-size;
  height: $aui-spinner-size;
  stroke: $aui-spinner-color;
  font-size: 1rem;

  &__svg {
    width: 100%;
    height: 100%;
  }

  &__path {
    stroke-miterlimit: 10;
    stroke-linecap: square;
    stroke-width: 1.1px;
    fill: none;
  }

  &__path--progress {
    stroke: $aui-spinner-color-progress;
    stroke-width: 3px;
  }

  &__value {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    font-family: $aui-font-extended;
    font-size: 1rem;
    line-height: 1;
    text-align: center;
  }

  &--continuous {
    width: $aui-spinner-size-continuous;
    height: $aui-spinner-size-continuous;
  }
}
