$font-path: "https://assets.myaudi.com.au/fonts" !default;

@font-face {
  font-family: 'AudiTypeScreen';
  src: url('#{$font-path}/Audi_Type_Screen/AudiTypeScreen-Light.eot'); // IE9 Compat Modes
  src: url('#{$font-path}/Audi_Type_Screen/AudiTypeScreen-Light.eot?#iefix') format('embedded-opentype'), // IE6-IE8
       url('#{$font-path}/Audi_Type_Screen/AudiTypeScreen-Light.woff2') format('woff2'), // Super Modern Browsers
       url('#{$font-path}/Audi_Type_Screen/AudiTypeScreen-Light.woff') format('woff'), // Pretty Modern Browsers
       url('#{$font-path}/Audi_Type_Screen/AudiTypeScreen-Light.ttf')  format('truetype'), // Safari, Android, iOS
       url('#{$font-path}/Audi_Type_Screen/AudiTypeScreen-Light.svg#AudiTypeScreen-Light') format('svg'); // Legacy iOS
  font-weight: 300;
  font-style: normal;
}

@font-face {
    font-family: "AudiTypeScreen";
    src: url("#{$font-path}/Audi_Type/Audi-Type-Normal.eot");
    src: url("#{$font-path}/Audi_Type/Audi-Type-Normal.eot?#iefix") format("embedded-opentype"),
         url("#{$font-path}/Audi_Type/Audi-Type-Normal.woff") format("woff"),
         url("#{$font-path}/Audi_Type/Audi-Type-Normal.ttf") format("truetype"),
         url("#{$font-path}/Audi_Type/Audi-Type-Normal.svg#AudiType-Normal") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "AudiTypeScreen";
    src: url("#{$font-path}/Audi_Type/Audi-Type-Bold.eot");
    src: url("#{$font-path}/Audi_Type/Audi-Type-Bold.eot?#iefix") format("embedded-opentype"),
         url("#{$font-path}/Audi_Type/Audi-Type-Bold.woff") format("woff"),
         url("#{$font-path}/Audi_Type/Audi-Type-Bold.ttf") format("truetype"),
         url("#{$font-path}/Audi_Type/Audi-Type-Bold.svg#AudiType-Bold_08") format("svg");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "AudiTypeExtended";
    src: url("#{$font-path}/Audi_Type_Extended/Audi-Type-Extended-Normal.eot");
    src: url("#{$font-path}/Audi_Type_Extended/Audi-Type-Extended-Normal.eot?#iefix") format("embedded-opentype"),
         url("#{$font-path}/Audi_Type_Extended/Audi-Type-Extended-Normal.woff") format("woff"),
         url("#{$font-path}/Audi_Type_Extended/Audi-Type-Extended-Normal.ttf") format("truetype"),
         url("#{$font-path}/Audi_Type_Extended/Audi-Type-Extended-Normal.svg#AudiType-ExtendedNormal_08") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "AudiTypeExtended";
    src: url("#{$font-path}/Audi_Type_Extended/Audi-Type-Extended-Bold.eot");
    src: url("#{$font-path}/Audi_Type_Extended/Audi-Type-Extended-Bold.eot?#iefix") format("embedded-opentype"),
         url("#{$font-path}/Audi_Type_Extended/Audi-Type-Extended-Bold.woff") format("woff"),
         url("#{$font-path}/Audi_Type_Extended/Audi-Type-Extended-Bold.ttf") format("truetype"),
         url("#{$font-path}/Audi_Type_Extended/Audi-Type-Extended-Bold.svg#AudiType-ExtendedBold_08") format("svg");
    font-weight: 700;
    font-style: normal;
}
