.page-header {
  
  @include media-breakpoint-up(small) {
    background-color: transparent;
    transition: background-color .4s;
    .page-header__top {
      transform: translate(0,0);
      transition: transform .4s;
    }
    &.search-open {
      background-color: $aui-color-white;
      .page-header__top {
        transform: translate(0, -15px);
      }
    }
    &.search-closing {
      background-color: transparent;
      .page-header__top {
        transform: translate(0,0);
      }
    }
  }
  
  >.content-wrapper {
    background-color: #fff;
    border-bottom: 1px solid #eee;
    height: 100%;
  }
  
  .logo {
    color: #313131;
    display: inline-block;
    margin: 15px 0;
    @include media-breakpoint-up(small) {
      margin-top: 36px;
    }
    h1 {
      line-height: 15px;
      @include media-breakpoint-up(small) {
        line-height: 18px;
      }
    }
    svg {
      height: 15px;
      width: 100%;
      @include media-breakpoint-up(small) {
        height: 18px;
      }
    }
  }
  .rings {
    display: inline-block;
    margin-top: 30px;
  }
  
  .btn-menu {
    color: $aui-color-black;
    width: 30px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    border: none;
    background: none;
    box-shadow: none;
    padding: 5px 0;
    transition: gap .2s;
    .line {
      display: block;
      height: 1px;
      width: 100%;
      background: currentColor;
      transition: transform .2s, opacity .2s;
    }
  }
  &.nav-open {
    .btn-menu {
      gap: 0;
      .line.mid { opacity: 0; }
      .line.top { transform: rotate(45deg) translate(0,1px); }
      .line.bot {transform: rotate(-45deg) translate(0,-1px); }
    }
  }
  
  .btn-search {
    color: $aui-color-black;
    border: none;
    background: none;
    padding: 0;
    box-shadow: none;
    cursor: pointer;
    @include media-breakpoint-up(small) {
      position: absolute;
      right: 0;
      top: 17px;
      align-items: center;
      gap: 5px;
      font-size: .9rem;
    }
  }
  
  .page-header__nav {
    @include media-breakpoint-up(small) {
      position: relative;
      padding-top: 14px;
      padding-right: 30px;
    }
    @include media-breakpoint-up(medium) {
      padding-right: 120px;
    }
    @include media-breakpoint-down(small) {
      position: fixed;
      inset: 0;
      top: 45px;
      background: #121b22;
      color: #fff;
      transform: translate(-100%,0);
      transition: transform .4s;
      &.nav-open {
        transform: translate(0,0);
      }
    }
  }
  
  .aui-nav--bar {
    .aui-nav__item {
      padding: 0 .6rem;
      @include media-breakpoint-up(medium) {
        padding: 0 .8rem;
      }
      @include media-breakpoint-up(xlarge) {
        padding: 0 1rem;
      }
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      &.donate .aui-nav__action {
        color: $aui-color-red !important;
      }
    }
    .aui-nav__action {
      color: $aui-color-black;
      @include media-breakpoint-up(small) {
        font-size: .75em;
      }
    }
    @include media-breakpoint-down(small) {
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;
      .aui-nav__panel {
        padding-top: 80px;
      }
      .aui-nav__items {
        flex-direction: column;
        border-top: 1px solid rgba(255,255,255,0.2);
      }
      .aui-nav__item {
        padding: 0;
        width: 100%;
        border-bottom: 1px solid rgba(255,255,255,0.2);
      }
      .aui-nav__action {
        color: rgba(255,255,255,0.5);
        padding: 1.1rem;
        display: block;
        &.is-active {
          color: #fff;
        }
      }
      .aui-nav__paddles, .aui-nav__indicator {
        display: none;
      }
    }
  }
}
